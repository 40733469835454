import {
  CalendarHeart,
  HeartHandshake,
  MapPin,
  Users,
  VenetianMask,
} from "lucide-react"

type SearchBarField = {
  id: string
  label: string
  inlineLabel?: boolean
  type: "combobox" | "select"
  classNames?: { wrapper?: string }
  icon?: React.ReactNode
  params: {
    multiselect?: boolean
    endpoint?: string
    valueKey?: string
    labelKey?: string
    options?: { value: string; label: string }[]
  }
}

export type SiteConfig = {
  name: string
  description: string
  mainNav: { title: string; href: string }[]
  links: Record<string, string>
  searchBar: {
    fields: SearchBarField[]
  }
}

export const siteConfig = {
  name: "Dalliance Domain - Independent Escort Booking Platform",
  description: "Dalliance Domain is Where Privacy Meets Pleasure. Find Escorts in Sydney, Melbourne, Brisbane, Gold Coast, Perth and Northern Territory. View, Message, Book and Pay all within the one App.",
  mainNav: [
    {
      title: "Home",
      href: "/",
    },
    {
      title: "Search",
      href: "/search",
    },
    {
      title: "About",
      href: "/about",
    },
  ],
  links: {
    signUp: "/sign-up",
    signIn: "/sign-in",
    createCustomer: "/sign-up?redirect_url=/create/customer/step-1",
    createCustomerSignedIn: "/create/customer/step-1",
    createEscort: "/sign-up?redirect_url=/create/escort/step-1",
    createEscortSignedIn: "/create/escort/step-1",
    createBrothel: "/create/brothel/step-1",
    createAgency: "/create/agency/step-1",
  },
  lists: {
    flatRatePeriods: [
      "Flat rate",
      "Flat_rate 2",
      "Flat rate 3",
      "Flat rate 4"
    ],
    ratePeriods: [
      "30 minutes",
      "45 minutes",
      "1 hour",
      "1.5 hours",
      "2 hours",
      "2.5 hours",
      "3 hours",
      "3.5 hours",
      "4 hours",
      "4.5 hours",
      "5 hours",
      "5.5 hours",
      "6 hours",
      "6.5 hours",
      "7 hours",
      "7.5 hours",
      "8 hours",
      "8.5 hours",
      "9 hours",
      "9.5 hours",
      "10 hours",
      "10.5 hours",
      "11 hours",
      "12 hours",
      "13 hours",
      "14 hours",
      "15 hours",
      "16 hours",
      "17 hours",
      "18 hours",
      "19 hours",
      "20 hours",
      "21 hours",
      "22 hours",
      "23 hours",
    ],
    serviceTypes: ["GFE", "NGFE", "PSE"],
    meetingTypes: ["In-Call", "Outcall", "Overnight", "Fly to you", "24hrs", "48hrs", "Lunch/Dinner", "Social", "Couples MMF", "Couples FFM"],
  },
  booking: {
    escort: {
      fields: [
        {
          id: "day",
          label: "Day",
          type: "input",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            type: "date",
          },
        },
        {
          id: "time",
          label: "Time",
          type: "input",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            type: "time",
          },
        },
        {
          id: "postcode",
          label: "Location",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "col-span-6" },
          params: {
            multiselect: false,
            endpoint: "/api/postcodes?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },
        {
          id: "type",
          label: "Booking Type",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            options: [
              { value: "incall", label: "Incall" },
              { value: "outcall", label: "Outcall" },
            ],
          },
        },
        {
          id: "type",
          label: "Booking Period",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            options: [
              { value: "45m", label: "45 minutes" },
              { value: "60m", label: "1 hour" },
              { value: "90m", label: "1.5 hours" },
              { value: "120m", label: "2 hours" },
              { value: "150m", label: "2.5 hours" },
              { value: "180m", label: "3 hours" },
            ],
          },
        },
        {
          id: "services",
          label: "Service type",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "md:col-span-3 col-span-6" },
          params: {
            multiselect: true,
            endpoint: "/api/services?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },
        {
          id: "xtra-services",
          label: "Additional services",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "md:col-span-3 col-span-6" },
          params: {
            multiselect: true,
            endpoint: "/api/services?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },

        {
          id: "payment",
          label: "Payment Method",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-6" },
          params: {
            options: [
              { value: "wallet", label: "Wallet funds (Cash)" },
              { value: "cc", label: "Credit Card" },
              { value: "coin", label: "DDCoin (Crypto)" },
            ],
          },
        },
      ],
    },
    brothel: {
      fields: [
        {
          id: "date",
          label: "Date",
          type: "input",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            type: "date",
          },
        },
        {
          id: "time",
          label: "Time",
          type: "input",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            type: "time",
          },
        },
        {
          id: "postcode",
          label: "Location",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "col-span-6" },
          params: {
            multiselect: false,
            endpoint: "/api/postcodes?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },
        {
          id: "type",
          label: "Booking Type",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            options: [
              { value: "incall", label: "Incall" },
              { value: "outcall", label: "Outcall" },
            ],
          },
        },
        {
          id: "type",
          label: "Booking Period",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            options: [
              { value: "45m", label: "45 minutes" },
              { value: "60m", label: "1 hour" },
              { value: "90m", label: "1.5 hours" },
              { value: "120m", label: "2 hours" },
              { value: "150m", label: "2.5 hours" },
              { value: "180m", label: "3 hours" },
            ],
          },
        },
        {
          id: "services",
          label: "Service type",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "md:col-span-3 col-span-6" },
          params: {
            multiselect: true,
            endpoint: "/api/services?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },
        {
          id: "xtra-services",
          label: "Additional services",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "md:col-span-3 col-span-6" },
          params: {
            multiselect: true,
            endpoint: "/api/services?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },

        {
          id: "payment",
          label: "Payment Method",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-6" },
          params: {
            options: [
              { value: "wallet", label: "Wallet funds (Cash)" },
              { value: "cc", label: "Credit Card" },
              { value: "coin", label: "DDCoin (Crypto)" },
            ],
          },
        },
      ],
    },
    agency: {
      fields: [
        {
          id: "date",
          label: "Date",
          type: "input",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            type: "date",
          },
        },
        {
          id: "time",
          label: "Time",
          type: "input",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            type: "time",
          },
        },
        {
          id: "postcode",
          label: "Location",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "col-span-6" },
          params: {
            multiselect: false,
            endpoint: "/api/postcodes?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },
        {
          id: "type",
          label: "Booking Type",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            multiselect: true,
            options: [
              { value: "incall", label: "Incall" },
              { value: "outcall", label: "Outcall" },
            ],
          },
        },
        {
          id: "type",
          label: "Booking Period",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-3" },
          params: {
            multiselect: true,
            options: [
              { value: "45m", label: "45 minutes" },
              { value: "60m", label: "1 hour" },
              { value: "90m", label: "1.5 hours" },
              { value: "120m", label: "2 hours" },
              { value: "150m", label: "2.5 hours" },
              { value: "180m", label: "3 hours" },
            ],
          },
        },
        {
          id: "services",
          label: "Service type",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "md:col-span-3 col-span-6" },
          params: {
            multiselect: true,
            endpoint: "/api/services?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },
        {
          id: "xtra-services",
          label: "Additional services",
          type: "combobox",
          inlineLabel: false,
          classNames: { wrapper: "md:col-span-3 col-span-6" },
          params: {
            multiselect: true,
            endpoint: "/api/services?q=",
            valueKey: "value",
            labelKey: "label",
          },
        },

        {
          id: "payment",
          label: "Payment Method",
          type: "select",
          inlineLabel: false,
          classNames: { wrapper: "col-span-6" },
          params: {
            multiselect: true,
            options: [
              { value: "wallet", label: "Wallet funds (Cash)" },
              { value: "cc", label: "Credit Card" },
              { value: "coin", label: "DDCoin (Crypto)" },
            ],
          },
        },
      ],
    },
  },
  filters: {
    fields: [
      {
        id: "ordering",
        label: "Rates",
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "cheapest_rate", label: "Low to High" },
            { value: "-cheapest_rate", label: "High to Low" },
          ],
        },
      },
      {
        id: "accepts_unverified",
        label: "Accepts unverified clients",
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "True", label: "Yes" },
          ],
        },
      },
      {
        id: "health_cert",
        label: "Sexual health check",
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "True", label: "Yes" },
          ],
        },
      },
      {
        id: "is_double_profile",
        label: "Single/double profiles",
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "No", label: "Single" },
            { value: "Yes", label: "Double" },
          ],
        },
      },
      {
        id: "accepts_bank",
        label: "Accepts Wallet payment",
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "Yes", label: "Yes" }
          ],
        },
      },
    ],
  },
  searchBar: {
    fields: [
      {
        id: "postcode",
        label: "Location",
        type: "combobox",
        dynamic: true,
        classNames: { wrapper: "col-span-3" },
        icon: MapPin,
        params: {
          multiselect: false,
          endpoint: "/api/postcodes?q=",
          valueKey: "label",
          labelKey: "label",
        },
        backendField: "location"
      },
      {
        id: "category",
        label: "Gender",
        type: "select",
        icon: Users,
        classNames: { wrapper: "col-span-2" },
        params: {
          multiselect: true,
          defaultValue: { id: 1, value: "Female", label: "Female" },
          options: [
            { id: 1, value: "Female", label: "Female" },
            { id: 2, value: "Male", label: "Male" },
            { id: 3, value: "Trans", label: "Trans"},
          ],
        },
      },
      {
        id: "age",
        label: "Age",
        type: "select",
        classNames: { wrapper: "col-span-2" },
        icon: CalendarHeart,
        params: {
          multiselect: true,
          options: [
            {
                "id": 1,
                "label": "18-21",
                "value": "18-21"
            },
            {
                "id": 2,
                "label": "22-25",
                "value": "22-25"
            },
            {
                "id": 3,
                "label": "26-30",
                "value": "26-30"
            },
            {
                "id": 4,
                "label": "31-35",
                "value": "31-35"
            },
            {
                "id": 5,
                "label": "36-40",
                "value": "36-40"
            },
            {
                "id": 6,
                "label": "40-45",
                "value": "40-45"
            },
            {
                "id": 7,
                "label": "45-50",
                "value": "45-50"
            },
            {
                "id": 8,
                "label": "50+",
                "value": "50plus"
            }
        ],
        },
      },
      {
        id: "meeting_type",
        label: "Meeting type",
        type: "select",
        icon: HeartHandshake,
        classNames: { wrapper: "col-span-3" },
        params: {
          multiselect: true,
          options: [
            { id: 1, value: "In-Call", label: "In-Call" },
            { id: 2, value: "Outcall", label: "Outcall" },
            { id: 3, value: "Overnight", label: "Overnight" },
            { id: 4, value: "Fly to you", label: "Fly to you" },
            { id: 5, value: "24hrs", label: "24hrs" },
            { id: 6, value: "48hrs", label: "48hrs" },
            { id: 7, value: "Lunch/Dinner", label: "Lunch/Dinner" },
            { id: 8, value: "Social", label: "Social" },
            { id: 9, value: "Couples MMF", label: "Couples MMF" },
            { id: 10, value: "Couples FFM", label: "Couples FFM" },
          ],
        },
      },
      {
        id: "services",
        label: "Services",
        type: "combobox",
        classNames: { wrapper: "col-span-5" },
        icon: VenetianMask,
        params: {
          multiselect: true,
          endpoint: "/api/categories?mode=all&type=services&q=",
          valueKey: "value",
          labelKey: "label",
        },
      },
    ],
  },
  advancedSearch: {
    fields: [
      {
        id: "postcode",
        label: "Location",
        section: 'Quick Search',
        type: "combobox",
        dynamic: true,
        classNames: { wrapper: "col-span-3" },
        icon: MapPin,
        params: {
          multiselect: false,
          endpoint: "/api/postcodes?q=",
          valueKey: "label",
          labelKey: "label",
        },
        backendField: "location"
      },
      {
        id: "category",
        label: "Gender",
        section: 'Quick Search',
        type: "select",
        icon: Users,
        classNames: { wrapper: "col-span-2" },
        params: {
          multiselect: true,
          defaultValue: { id: 1, value: "Female", label: "Female" },
          options: [
            { id: 1, value: "Female", label: "Female" },
            { id: 2, value: "Male", label: "Male" },
            { id: 3, value: "Trans", label: "Trans"},
          ],
        }
      },
      {
        id: "age",
        label: "Age",
        type: "select",
        section: 'Quick Search',
        classNames: { wrapper: "col-span-2" },
        icon: CalendarHeart,
        params: {
          multiselect: true,
          options: [
            {
                "id": 1,
                "label": "18-21",
                "value": "18-21"
            },
            {
                "id": 2,
                "label": "22-25",
                "value": "22-25"
            },
            {
                "id": 3,
                "label": "26-30",
                "value": "26-30"
            },
            {
                "id": 4,
                "label": "31-35",
                "value": "31-35"
            },
            {
                "id": 5,
                "label": "36-40",
                "value": "36-40"
            },
            {
                "id": 6,
                "label": "40-45",
                "value": "40-45"
            },
            {
                "id": 7,
                "label": "45-50",
                "value": "45-50"
            },
            {
                "id": 8,
                "label": "50+",
                "value": "50plus"
            }
        ],
        },
      },
      {
        id: "meeting_type",
        label: "Meeting type",
        section: 'Quick Search',
        type: "select",
        icon: HeartHandshake,
        classNames: { wrapper: "col-span-3" },
        params: {
          multiselect: true,
          options: [
            { id: 1, value: "In-Call", label: "In-Call" },
            { id: 2, value: "Outcall", label: "Outcall" },
            { id: 3, value: "Overnight", label: "Overnight" },
            { id: 4, value: "Fly to you", label: "Fly to you" },
            { id: 5, value: "24hrs", label: "24hrs" },
            { id: 6, value: "48hrs", label: "48hrs" },
            { id: 7, value: "Lunch/Dinner", label: "Lunch/Dinner" },
            { id: 8, value: "Social", label: "Social" },
            { id: 9, value: "Couples MMF", label: "Couples MMF" },
            { id: 10, value: "Couples FFM", label: "Couples FFM" },
          ],
        },
      },
      {
        id: "services",
        label: "Services",
        section: 'Quick Search',
        type: "combobox",
        inlineLabel: false,
        classNames: { wrapper: "md:col-span-3 col-span-6" },
        params: {
          multiselect: true,
          endpoint: "/api/categories?mode=all&type=services&q=",
          valueKey: "value",
          labelKey: "label",
        },
      },
      {
        id: "accepts_unverified",
        label: "Accepts unverified clients",
        section: 'Filters',
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "True", label: "Yes" },
          ],
        },
      },
      {
        id: "exclusive",
        label: "Exclusive Escort",
        section: 'Filters',
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "True", label: "Yes" },
            { value: "False", label: "No" },            
          ],
        },
      },      
      {
        id: "health_cert",
        label: "Sexual health check",
        section: 'Filters',
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "True", label: "Yes" },
          ],
        },
      },
      {
        id: "is_double_profile",
        section: 'Filters',
        label: "Single/double profiles",
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "No", label: "Single" },
            { value: "Yes", label: "Double" },
          ],
        },
      },
      {
        id: "accepts_bank",
        label: "Accepts Wallet payment",
        section: 'Filters',
        type: "select",
        params: {
          multiselect: false,
          options: [
            { value: "Yes", label: "Yes" }
          ],
        },
      },
      {
        id: "day",
        label: "Day",
        section: 'Filters',
        type: "input",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect:true,
          options: [
            { id: 1, value: "Monday", label: "Monday" },
            { id: 2, value: "Tuesday", label: "Tuesday" },
            { id: 3, value: "Wednesday", label: "Wednesday" },
            { id: 4, value: "Thursday", label: "Thursday" },
            { id: 5, value: "Friday", label: "Friday" },
            { id: 6, value: "Saturday", label: "Saturday" },
            { id: 7, value: "Sunday", label: "Sunday" }
          ]
        },
        backendField: "schedule_date",
      },
      {
        id: "time",
        label: "Time",
        section: 'Filters',
        type: "input",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          hideInput:true,
          multiselect:true,
          options:[
            { id: 1, value: "00:00", label: "12:00 AM" },
            { id: 2, value: "00:30", label: "12:30 AM" },
            { id: 3, value: "01:00", label: "1:00 AM" },
            { id: 4, value: "01:30", label: "1:30 AM" },
            { id: 5, value: "02:00", label: "2:00 AM" },
            { id: 6, value: "02:30", label: "2:30 AM" },
            { id: 7, value: "03:00", label: "3:00 AM" },
            { id: 8, value: "03:30", label: "3:30 AM" },
            { id: 9, value: "04:00", label: "4:00 AM" },
            { id: 10, value: "04:30", label: "4:30 AM" },
            { id: 11, value: "05:00", label: "5:00 AM" },
            { id: 12, value: "05:30", label: "5:30 AM" },
            { id: 13, value: "06:00", label: "6:00 AM" },
            { id: 14, value: "06:30", label: "6:30 AM" },
            { id: 15, value: "07:00", label: "7:00 AM" },
            { id: 16, value: "07:30", label: "7:30 AM" },
            { id: 17, value: "08:00", label: "8:00 AM" },
            { id: 18, value: "08:30", label: "8:30 AM" },
            { id: 19, value: "09:00", label: "9:00 AM" },
            { id: 20, value: "09:30", label: "9:30 AM" },
            { id: 21, value: "10:00", label: "10:00 AM" },
            { id: 22, value: "10:30", label: "10:30 AM" },
            { id: 23, value: "11:00", label: "11:00 AM" },
            { id: 24, value: "11:30", label: "11:30 AM" },
            { id: 25, value: "12:00", label: "12:00 PM" },
            { id: 26, value: "12:30", label: "12:30 PM" },
            { id: 27, value: "13:00", label: "1:00 PM" },
            { id: 28, value: "13:30", label: "1:30 PM" },
            { id: 29, value: "14:00", label: "2:00 PM" },
            { id: 30, value: "14:30", label: "2:30 PM" },
            { id: 31, value: "15:00", label: "3:00 PM" },
            { id: 32, value: "15:30", label: "3:30 PM" },
            { id: 33, value: "16:00", label: "4:00 PM" },
            { id: 34, value: "16:30", label: "4:30 PM" },
            { id: 35, value: "17:00", label: "5:00 PM" },
            { id: 36, value: "17:30", label: "5:30 PM" },
            { id: 37, value: "18:00", label: "6:00 PM" },
            { id: 38, value: "18:30", label: "6:30 PM" },
            { id: 39, value: "19:00", label: "7:00 PM" },
            { id: 40, value: "19:30", label: "7:30 PM" },
            { id: 41, value: "20:00", label: "8:00 PM" },
            { id: 42, value: "20:30", label: "8:30 PM" },
            { id: 43, value: "21:00", label: "9:00 PM" },
            { id: 44, value: "21:30", label: "9:30 PM" },
            { id: 45, value: "22:00", label: "10:00 PM" },
            { id: 46, value: "22:30", label: "10:30 PM" },
            { id: 47, value: "23:00", label: "11:00 PM" },
            { id: 48, value: "23:30", label: "11:30 PM" }
          ]
        },
        backendField: "schedule_time",
      },

      {
        id: "price",
        label: "Rate",
        section: 'Filters',
        type: "input",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect:true,
          hideInput:true,
          options:[
              { id: 3, value: 500, label: "$500" },
              { id: 4, value: 600, label: "$600" },
              { id: 5, value: 700, label: "$700" },
              { id: 6, value: 800, label: "$800" },
              { id: 7, value: 900, label: "$900" },
              { id: 8, value: 1000, label: "$1000" },
              { id: 9, value: 1100, label: "$1100" },
              { id: 10, value: 1200, label: "$1200" },
              { id: 11, value: 1300, label: "$1300" },
              { id: 12, value: 1400, label: "$1400" },
              { id: 13, value: 1500, label: "$1500+" },
          ]
        },
        backendField: "schedule_time",
      },





      {
        id: "hair",
        conditions: {
          "gender": ["Female", "Trans"],
        },
        label: "Hair",
        section: 'Advanced Search',
        type: "select",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect: true,
          options: [
            { id: 1, value: "Blonde", label: "Blonde" },
            { id: 2, value: "Black", label: "Black" },
            { id: 3, value: "Brunette", label: "Brunette" },
            { id: 4, value: "Platinum Blonde", label: "Platinum Blonde" },
            { id: 5, value: "Strawberry Blonde", label: "Strawberry Blonde" },
            { id: 6, value: "Auburn", label: "Auburn" },
            { id: 7, value: "Caramel", label: "Caramel" },
            { id: 8, value: "Red", label: "Red" },
            { id: 9, value: "Grey", label: "Grey" },
            { id: 10, value: "Pastel", label: "Pastel" },
            { id: 11, value: "Pink", label: "Pink" },
            { id: 12, value: "Purple", label: "Purple" },
            { id: 13, value: "Blue", label: "Blue" },
            { id: 14, value: "Green", label: "Green" },
          ],
        },
        backendField: "hair",
      },
      {
        id: "eyes",
        label: "Eyes",
        section: 'Advanced Search',
        type: "select",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect: true,
          options: [
            { id: 1, value: "Brown", label: "Brown" },
            { id: 2, value: "Blue", label: "Blue" },
            { id: 3, value: "Green", label: "Green" },
            { id: 4, value: "Blue/Green", label: "Blue/Green" },
            { id: 5, value: "Hazel", label: "Hazel" },
            { id: 6, value: "Grey", label: "Grey" },
            { id: 7, value: "Amber", label: "Amber" },
          ],
        },
      },
      {
        id: "body",
        conditions: {
          "gender": ["Female"]
        },
        label: "Body type",
        section: 'Advanced Search',
        type: "select",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect: true,
          options: [
            {  id: 1, value: "Toned", label: "Toned" },
            {  id: 2, value: "Petite", label: "Petite" },
            {  id: 3, value: "Slim", label: "Slim" },
            {  id: 4, value: "Average", label: "Average" },
            {  id: 5, value: "Hour Glass", label: "Hour Glass" },
            {  id: 6, value: "Curvy", label: "Curvy" },
            {  id: 7, value: "Busty", label: "Busty" },
            {  id: 8, value: "Voluptuous", label: "Voluptuous" },
            {  id: 9, value: "Cuddly", label: "Cuddly" },
            {  id: 10, value: "BBW", label: "BBW" },
          ],
        },
        backendField: "body_type"
      },
      {
        id: "dress",
        conditions: {
          "gender": ["Female", "Trans"]
        },
        label: "Dress Size",
        section: 'Advanced Search',
        type: "select",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect: true,
          options: [
            {  id: 1, value: "4", label: "4" },
            {  id: 2, value: "6", label: "6" },
            {  id: 3, value: "8", label: "8" },
            { id: 4, value: "10", label: "10" },
            { id: 5, value: "12", label: "12" },
            { id: 6,  value: "14", label: "14" },
            { id: 7,  value: "16", label: "16" },
            { id: 8,  value: "18", label: "18" },
            { id: 9,  value: "20", label: "20plus"},
          ],
        },
        backendField: "dress_size"
      },
      {
        id: "breast",
        label: "Breast size",
        section: 'Advanced Search',
        type: "select",
        inlineLabel: false,
        conditions: {
          "gender": ["Female", "Trans"]
        },
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect: true,
          options: [
            { id: 1, value: "A", label: "A" },
            { id: 2, value: "B", label: "B" },
            { id: 3, value: "C", label: "C" },
            { id: 4,  value: "D", label: "D" },
            { id: 5,  value: "DD", label: "DD" },
            { id: 6,  value: "E", label: "E" },
            { id: 7,  value: "F", label: "F" },
            { id: 8,  value: "G+", label: "G+" },
          ],
        },
        backendField: "breast_size"
      },
      {
        id: "height",
        label: "Height",
        section: 'Advanced Search',
        type: "select",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect: true,
          options: [
            // Mo: I subbed in ” instead of \" as that was breaking selectors
            { id: 1,  label: "4'5” (135 cm)", value: 135 },
            { id: 2,  label: "4'6” (137 cm)", value: 137 },
            { id: 3,  label: "4'7” (140 cm)", value: 140 },
            { id: 4,  label: "4'8” (142 cm)", value: 142 },
            { id: 5,  label: "4'9” (145 cm)", value: 145 },
            { id: 6,  label: "4'10” (147 cm)", value: 147 },
            { id: 7,  label: "4'11” (150 cm)", value: 150 },
            { id: 8,  label: "5'0” (152 cm)", value: 152 },
            { id: 9,  label: "5'1” (155 cm)", value: 155 },
            { id: 10,  label: "5'2” (157 cm)", value: 157 },
            { id: 11,  label: "5'3” (160 cm)", value: 160 },
            { id: 12,  label: "5'4” (163 cm)", value: 163 },
            { id: 13,  label: "5'5” (165 cm)", value: 165 },
            { id: 14,  label: "5'6” (168 cm)", value: 168 },
            { id: 15,  label: "5'7” (170 cm)", value: 170 },
            { id: 16,  label: "5'8” (173 cm)", value: 173 },
            { id: 17,  label: "5'9” (175 cm)", value: 175 },
            { id: 18,  label: "5'10” (178 cm)", value: 178 },
            { id: 19,  label: "5'11” (180 cm)", value: 180 },
            { id: 20,  label: "6'0” (183 cm)", value: 183 },
            { id: 21,  label: "6'1” (185 cm)", value: 185 },
            { id: 22,  label: "6'2” (188 cm)", value: 188 },
            { id: 23,  label: "6'3” (190 cm)", value: 190 },
            { id: 24,  label: "6'4” (193 cm)", value: 193 },
            { id: 25,  label: "6'5” (196 cm)", value: 196 },
            { id: 26,  label: "6'6” (198 cm)", value: 198 },
            { id: 27,  label: "6'7” (201 cm)", value: 201 },
            { id: 28,  label: "6'8” (203 cm)", value: 203 },
            { id: 29,  label: "6'9” (206 cm)", value: 206 },
            { id: 30,  label: "6'10” (208 cm)", value: 208 },
            { id: 31,  label: "6'11” (211 cm)", value: 211 },
            { id: 32,  label: "7'0” (213 cm)", value: 213 },
          ],
        },
      },
      {
        id: "ethnicity",
        label: "Ethnicity",
        section: 'Advanced Search',
        type: "select",
        inlineLabel: false,
        classNames: { wrapper: "col-span-1" },
        params: {
          multiselect: true,
          options: [
            { id: 1, value: "African", label: "African" },
            { id: 2, value: "American", label: "American" },
            { id: 3, value: "Asian", label: "Asian" },
            { id: 4, value: "Australian", label: "Australian" },
            { id: 5, value: "Canadian", label: "Canadian" },
            { id: 6, value: "Caribbean", label: "Caribbean" },
            { id: 7, value: "Caucasian", label: "Caucasian" },
            { id: 8, value: "Eastern European", label: "Eastern European" },
            { id: 9, value: "Euroasian", label: "Euroasian" },
            { id: 10, value: "European", label: "European" },
            { id: 11, value: "Greek", label: "Greek" },
            { id: 12, value: "Indian", label: "Indian" },
            { id: 13, value: "Italian", label: "Italian" },
            { id: 14, value: "Latino", label: "Latino" },
            { id: 15, value: "Middle Eastern", label: "Middle Eastern" },
            { id: 16, value: "Mixed Ethnicity", label: "Mixed Ethnicity" },
            { id: 17, value: "Russian", label: "Russian" },
            { id: 18, value: "Scandinavian", label: "Scandinavian" },
          ],
        },
      },
    ],
  },
}
